@import '../../../../scss/theme-bootstrap';

.content-block-tout {
  max-width: $content-block-max-width;
  margin: auto;
  overflow: hidden;
  position: relative;
  @include breakpoint($small-down) {
    text-align: center;
  }
  &__media {
    position: relative;
    @include breakpoint($medium-up) {
      &:hover .content-block-tout__media-hover {
        opacity: 1;
      }
    }
    video {
      display: none;
    }
    &.has-video {
      video {
        display: block;
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
    }
  }
  &__media-hover {
    opacity: 0;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.4);
  }
  &__media-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__image {
    display: block;
    margin: auto;
    width: 100%;
  }
  &__text {
    position: relative;
    padding: 20px 0;
  }
  &__text-eyebrow {
    @include text--1;
  }
  &__text-headline {
    @include heading--2;
  }
  &__text-body {
    @include text--1;
    margin-top: 20px;
  }
  &__buttons {
    margin-top: 20px;
  }
  &__details {
    margin-top: 40px;
    color: $color-gray;
    @include breakpoint($medium-up) {
      margin-top: 25px;
    }
  }
  &__details-reveal {
    visibility: hidden;
    top: 100%;
    position: absolute;
    transition: all 0.3s;
    opacity: 0;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: $color-white;
    padding: 15px;
  }
  &__details-copy {
    text-align: left;
    margin-top: 30px;
  }
  .active-text {
    display: none;
  }
  .inactive-text {
    display: block;
  }
  .content-block-tout__details-trigger,
  .content-block-tout__details-close {
    display: inline-block;
  }
  &.details-active {
    .content-block-tout__details-reveal {
      opacity: 1;
      top: 0;
      visibility: visible;
    }
    .inactive-text {
      display: none;
    }
    .active-text {
      display: block;
    }
  }
  .product-grid .product-image {
    max-width: 120px;
  }
}

.content-block-tout--hover {
  @include breakpoint($large-up) {
    .content-block-tout__details {
      opacity: 0;
      transition: all 0.3s;
    }
    &:hover .content-block-tout__details {
      opacity: 1;
    }
  }
}

.content-block-tout--inline {
  .content-block-tout__details-reveal {
    position: relative;
    overflow: auto;
    max-width: $content-block-max-width;
    margin: auto;
  }
  .content-block-tout__details-close {
    display: none;
  }
}

.content-block-tout--columns {
  @include breakpoint($medium-up) {
    .content-block-tout__inner {
      display: flex;
      width: 100%;
      align-items: center;
    }
    .content-block-tout__media,
    .content-block-tout__text {
      flex: 0 0 50%;
    }
    &.order-image-text {
      .content-block-tout__text {
        order: 1;
      }
    }
    &.order-text-image {
      .content-block-tout__media {
        order: 1;
      }
    }
  }
}
